import Apparemment from "../Components/3D/Apparemment";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div className="text-justify	">
      <Helmet>
        <title>La Galerie VR | Appartement </title>
      </Helmet>
      <Apparemment />
    </div>
  );
}
