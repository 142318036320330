import {
  EnvelopeIcon,
  PhoneIcon,
  GlobeEuropeAfricaIcon,
} from "@heroicons/react/24/outline";
import { db } from "../../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import { useState } from "react";
import ContactModal from "../Modal/ContactModal";
import ErrorModal from "../Modal/ErrorModal";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");

  const [confrimed, setconfrimed] = useState(false);
  const [error, setError] = useState(false);

  const contactCollectionRef = collection(db, "contact");
  const createContact = async () => {
    await addDoc(contactCollectionRef, {
      email: email,
      name: name,
      phone: phone,
      text: text,
    });
  };

  const handleSubmit = async (e) => {
    if (name !== "" && phone !== "" && email !== "" && text !== "") {
      try {
        createContact();
        setconfrimed(true);
      } catch (error) {
        setError(true);
      }
    } else {
      setError(true);
    }
  };
  const { t } = useTranslation();

  return (
    <div data-theme="lofi" className="py-8">
      <div>
        <div className="relative mx-auto px-8 min-w-full my-8 ">
          <div
            tabIndex={0}
            className="collapse collapse-arrow border border-base-300 bg-gray-50 "
          >
            <input type="checkbox" />
            <div className="collapse-title px-8">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                <div dangerouslySetInnerHTML={{ __html: t("about_title") }} />
              </h2>{" "}
            </div>

            <div className="collapse-content px-8">
              <p className="mt-3 text-lg leading-6 text-gray-500 text-justify	">
                <div dangerouslySetInnerHTML={{ __html: t("about_text") }} />
              </p>
            </div>
          </div>
        </div>

        <div className="relative mx-auto px-8 min-w-full lg:grid lg:grid-cols-5 ">
          <div className="bg-gray-50 border py-8  border-base-300 pb-5 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12 ">
            <div className=" ">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                {t("contact_title")}
              </h2>

              <p className="mt-3 text-lg leading-6 text-gray-500 text-justify	">
                <div dangerouslySetInnerHTML={{ __html: t("contact_text") }} />
              </p>
              <dl className="mt-8 text-base text-gray-500">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd className="flex">
                    <GlobeEuropeAfricaIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{t("location")}</span>
                  </dd>
                </div>
                <div className="my-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+216 50 73 89 26</span>
                  </dd>
                </div>
                <div className="">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <EnvelopeIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">Contact@lagalerievr.com</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white py-8  px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form
                action="https://formsubmit.co/3b932283f3b40106984626b84d6be5a9"
                method="POST"
                className="grid grid-cols-1 gap-y-6"
              >
                <div>
                  <label htmlFor="full-name" className="sr-only">
                    Name
                  </label>
                  <input
                    required
                    type="text"
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    name="full-name"
                    id="full-name"
                    autoComplete="name"
                    className="block w-full   border-b py-3 px-4 placeholder-gray-500 focus:ring-zinc-500 focus:border-zinc-500  border-zinc-900 rounded-none"
                    placeholder={t("form_name")}
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    required
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full border-b py-3 px-4 placeholder-gray-500 focus:ring-zinc-500 focus:border-zinc-500  border-zinc-900 rounded-none"
                    placeholder={t("form_email")}
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <input
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                    required
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="block w-full border-b py-3 px-4 placeholder-gray-500 focus:ring-zinc-500 focus:border-zinc-500  border-zinc-900 rounded-none"
                    placeholder={t("form_phone")}
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    required
                    onChange={(event) => {
                      setText(event.target.value);
                    }}
                    id="message"
                    name="message"
                    rows={3}
                    className="block w-full py-3 px-4 placeholder-gray-500 focus:ring-zinc-500 focus:border-zinc-500 border-b	 border-zinc-900 rounded-none"
                    placeholder={t("form_message")}
                    defaultValue={""}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-none text-white bg-zinc-900 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("button_submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ContactModal open={confrimed} setOpen={setconfrimed} />

      <ErrorModal open={error} setOpen={setError} />
    </div>
  );
}
