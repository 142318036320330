import React from "react";
import SpaceI from "../Components/3D/SpaceI";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>La Galerie VR | SpaceI</title>
      </Helmet>
      <SpaceI />
    </div>
  );
}
