import Scan from "../Components/LandingPage/Scan";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div className="text-justify	">
      <Helmet>
        <title>La Galerie VR | 3D</title>
      </Helmet>
      <Scan />
    </div>
  );
}
