import React from "react";
import Exhibitions from "../Components/LandingPage/Exhibitions";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>La Galerie VR | Exhibitions</title>
      </Helmet>
      <Exhibitions />
    </div>
  );
}
