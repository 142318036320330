import Gallery from "../Components/3D/Gallery";
import Timer from "../Components/LandingPage/Timer";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div>
      <Helmet>
        <title>La Galerie VR | Unseen</title>
      </Helmet>
      <Gallery />
    </div>
  );
}
