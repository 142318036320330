import { Fragment, useEffect } from "react";
import logo from "../../Assets/logo.svg";
import icon from "../../Assets/icon.png";
import { Popover, Transition, Menu } from "@headlessui/react";
import {
  CubeIcon,
  CubeTransparentIcon,
  GlobeAltIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import i18next from "i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const languages = [
  {
    code: "fr",
    name: "Français",
  },
  {
    code: "en",
    name: "English",
  },
];

export default function Navbar() {
  const currentLanguageCode = cookies.get("i18next");
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  return (
    <div className=" z-50">
      <Popover className="relative bg-white z-50">
        <div className="mx-auto px-8 min-w-full">
          <div className="flex items-center justify-between border-b-2 border-zinc-100 py-2 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <img
                  className="block  h-10 sm:h-18 w-auto"
                  src={logo}
                  alt="Workflow"
                />
              </Link>
            </div>
            <Popover.Button className="inline-flex items-center justify-center rounded-none bg-white p-2 text-zinc-400 hover:bg-zinc-100 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-zinc-500">
              <div className="-my-2 -mr-2 md:hidden">
                <span className="sr-only">Open menu</span>
                <img
                  className="block  m-2 h-6 w- sm:h-18 w-auto"
                  src={icon}
                  alt="Workflow"
                />{" "}
              </div>
            </Popover.Button>

            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              <Popover.Group as="nav" className="hidden space-x-10 md:flex">
                {/* .................... */}

                <Popover className="relative">
                  {({ open }) => (
                    <Menu
                      as="gallery"
                      className="relative inline-block text-left"
                    >
                      <div>
                        <Menu.Button className="inline-flex  w-full justify-center gap-x-1.5  bg-white px-0 py-0 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                          <div
                            className={classNames(
                              open ? "text-zinc-900" : "text-zinc-500",
                              "group inline-flex items-center rounded-none bg-white text-base font-medium hover:text-zinc-900 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2"
                            )}
                          >
                            <span>{t("gallery")}</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-zinc-600" : "text-zinc-400",
                                "ml-2 h-5 w-5 group-hover:text-zinc-500"
                              )}
                              aria-hidden="true"
                            />
                          </div>{" "}
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 opacity-90 -ml-4 mt-3 w-screen max-w-fit	 transform  lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                          <div className="overflow-hidden rounded-none	 shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              <Menu.Item key={1}>
                                <Link to="/opencall">
                                  {" "}
                                  <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50 min-w-max">
                                    <p className="text-base font-medium text-zinc-900">
                                      {t("open_call")}
                                    </p>
                                  </div>
                                </Link>
                              </Menu.Item>

                              <Menu.Item key={2}>
                                <Link to="/exhibitions">
                                  {" "}
                                  <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                    <p className="text-base font-medium text-zinc-900">
                                      {t("exhibitions")}
                                    </p>
                                  </div>
                                </Link>
                              </Menu.Item>
                              <Menu.Item key={3}>
                                <Link to="/artists">
                                  {" "}
                                  <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                    <p className="text-base font-medium text-zinc-900">
                                      {t("artists")}
                                    </p>
                                  </div>
                                </Link>
                              </Menu.Item>
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </Popover>
                {/* .................... */}

                {/* .................... */}

                <Popover className="relative">
                  {({ open }) => (
                    <Menu
                      as="services"
                      className="relative inline-block text-left"
                    >
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  bg-white px-0 py-0 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                          <div
                            className={classNames(
                              open ? "text-zinc-900" : "text-zinc-500",
                              "group inline-flex items-center rounded-none bg-white text-base font-medium hover:text-zinc-900 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2"
                            )}
                          >
                            <span>{t("3d_solutions")}</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-zinc-600" : "text-zinc-400",
                                "ml-2 h-5 w-5 group-hover:text-zinc-500"
                              )}
                              aria-hidden="true"
                            />
                          </div>{" "}
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 opacity-90 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-5 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                          <div className="overflow-hidden rounded-none	 shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              <Menu.Item key={1}>
                                <Link key="Replica" to="replica">
                                  <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                    <Square3Stack3DIcon
                                      className="h-6 w-6 flex-shrink-0 text-zinc-900"
                                      aria-hidden="true"
                                    />
                                    <div className=" grid ml-4">
                                      <p className="text-base font-medium text-zinc-900">
                                        Replica
                                      </p>
                                      <p className="mt-1 text-sm text-zinc-500">
                                        {t("replica_navbar_text")}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </Menu.Item>

                              <Menu.Item key={2}>
                                <Link key="Art Box" to="artbox">
                                  <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                    <CubeIcon
                                      className="h-6 w-6 flex-shrink-0 text-zinc-900"
                                      aria-hidden="true"
                                    />
                                    <div className=" grid ml-4">
                                      <p className="text-base font-medium text-zinc-900">
                                        Art Box
                                      </p>
                                      <p className="mt-1 text-sm text-zinc-500">
                                        {t("artbox_navbar_text")}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </Menu.Item>
                              <Menu.Item key={3}>
                                <Link key="Scan" to="scan">
                                  <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                    <CubeTransparentIcon
                                      className="h-6 w-6 flex-shrink-0 text-zinc-900"
                                      aria-hidden="true"
                                    />
                                    <div className=" grid ml-4">
                                      <p className="text-base font-medium text-zinc-900">
                                        3D Scan{" "}
                                      </p>
                                      <p className="mt-1 text-sm text-zinc-500">
                                        {t("scan_navbar_text")}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </Menu.Item>
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </Popover>
                {/* .................... */}

                <Link
                  to="/contact"
                  className="text-base font-medium text-zinc-500 hover:text-zinc-900"
                >
                  {t("contact")}
                </Link>

                <Link
                  to="/faq"
                  className="text-base font-medium text-zinc-500 hover:text-zinc-900"
                >
                  {t("faq")}
                </Link>
              </Popover.Group>
            </div>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  bg-white px-0 py-0 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                  <GlobeAltIcon
                    className="-mr-1 h-7 w-7 text-gray-500"
                    aria-hidden="true"
                  />

                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute text-center	 right-0 shadow-lg ring-1 opacity-90	 ring-black ring-opacity-5 z-10 mt-2 bg-white  ">
                  <div className="py-1">
                    {languages.map(({ code, name, country_code }) => (
                      <Menu.Item key={country_code}>
                        <a
                          href="#"
                          className="flex text-gray-700 block text-center px-4 py-2 text-sm"
                          onClick={() => {
                            i18next.changeLanguage(code);
                          }}
                        >
                          <span
                            style={{
                              color: currentLanguageCode === code ? "black" : 1,
                              "font-weight":
                                currentLanguageCode === code ? "bold" : 1,
                            }}
                            className="-m-3 flex rounded-none p-3 hover:bg-zinc-50 min-w-max text-base font-medium text-zinc-900"
                          >
                            {name}
                          </span>
                        </a>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="divide-y-2 divide-zinc-50 rounded-none bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <Link to="/">
                    <Popover.Button>
                      <img
                        className="block h-10 w-auto"
                        src={logo}
                        alt="Workflow"
                      />
                    </Popover.Button>
                  </Link>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-none	 bg-white p-2 text-zinc-400 hover:bg-zinc-100 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-zinc-500">
                      <span className="sr-only">Close menu</span>
                      <p className="font-bold">X</p>{" "}
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {/* .................... */}

                    <Popover className="relative">
                      {({ open }) => (
                        <Menu
                          as="gallery"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  bg-white px-0 py-0 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                              <div
                                className={classNames(
                                  open ? "text-zinc-900" : "text-zinc-500",
                                  "group inline-flex items-center rounded-none bg-white text-base font-medium hover:text-zinc-900 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2"
                                )}
                              >
                                <span>{t("gallery")}</span>
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? "text-zinc-600" : "text-zinc-400",
                                    "ml-2 h-5 w-5 group-hover:text-zinc-500"
                                  )}
                                  aria-hidden="true"
                                />
                              </div>{" "}
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute z-10  -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-5 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                              <div className="overflow-hidden rounded-none	 shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  <Menu.Item key={1}>
                                    <Link to="/opencall">
                                      {" "}
                                      <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                        <div className=" grid ml-4">
                                          <p className="text-base font-medium text-zinc-900">
                                            {t("open_call")}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </Menu.Item>

                                  <Menu.Item key={2}>
                                    <Link to="/exhibitions">
                                      {" "}
                                      <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                        <div className=" grid ml-4">
                                          <p className="text-base font-medium text-zinc-900">
                                            {t("exhibitions")}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </Menu.Item>
                                  <Menu.Item key={3}>
                                    <Link to="/artists">
                                      {" "}
                                      <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                        <div className=" grid ml-4">
                                          <p className="text-base font-medium text-zinc-900">
                                            {t("artists")}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </Menu.Item>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </Popover>
                    {/* .................... */}

                    {/* .................... */}

                    <Popover className="relative">
                      {({ open }) => (
                        <Menu
                          as="services"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  bg-white px-0 py-0 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                              <div
                                className={classNames(
                                  open ? "text-zinc-900" : "text-zinc-500",
                                  "group inline-flex items-center rounded-none bg-white text-base font-medium hover:text-zinc-900 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2"
                                )}
                              >
                                <span>{t("3d_solutions")}</span>
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? "text-zinc-600" : "text-zinc-400",
                                    "ml-2 h-5 w-5 group-hover:text-zinc-500"
                                  )}
                                  aria-hidden="true"
                                />
                              </div>{" "}
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute z-10  -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-5 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                              <div className="overflow-hidden rounded-none	 shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  <Menu.Item key={1}>
                                    <Link key="Replica" to="replica">
                                      <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                        <Square3Stack3DIcon
                                          className="h-6 w-6 flex-shrink-0 text-zinc-900"
                                          aria-hidden="true"
                                        />
                                        <div className=" grid ml-4">
                                          <p className="text-base font-medium text-zinc-900">
                                            Replica
                                          </p>
                                          <p className="mt-1 text-sm text-zinc-500">
                                            {t("replica_navbar_text")}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </Menu.Item>

                                  <Menu.Item key={2}>
                                    <Link key="Art Box" to="artbox">
                                      <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                        <CubeIcon
                                          className="h-6 w-6 flex-shrink-0 text-zinc-900"
                                          aria-hidden="true"
                                        />
                                        <div className=" grid ml-4">
                                          <p className="text-base font-medium text-zinc-900">
                                            Art Box
                                          </p>
                                          <p className="mt-1 text-sm text-zinc-500">
                                            {t("artbox_navbar_text")}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </Menu.Item>
                                  <Menu.Item key={3}>
                                    <Link key="Scan" to="scan">
                                      <div className="-m-3 flex rounded-none p-3 hover:bg-zinc-50">
                                        <CubeTransparentIcon
                                          className="h-6 w-6 flex-shrink-0 text-zinc-900"
                                          aria-hidden="true"
                                        />
                                        <div className=" grid ml-4">
                                          <p className="text-base font-medium text-zinc-900">
                                            3D Scan{" "}
                                          </p>
                                          <p className="mt-1 text-sm text-zinc-500">
                                            {t("scan_navbar_text")}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </Menu.Item>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </Popover>
                    {/* .................... */}

                    <Link
                      to="/contact"
                      className="text-base font-medium text-zinc-500 hover:text-zinc-900"
                    >
                      {" "}
                      <Popover.Button> {t("contact")}</Popover.Button>
                    </Link>
                    <Link
                      to="/faq"
                      className="text-base font-medium text-zinc-500 hover:text-zinc-900"
                    >
                      <Popover.Button>{t("faq")}</Popover.Button>
                    </Link>
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
