import React from "react";
import ArtistsCards from "../Components/LandingPage/ArtistsCards";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>La Galerie VR | Artists</title>
      </Helmet>
      <ArtistsCards />
    </div>
  );
}
