import React from "react";
import SpaceII from "../Components/3D/SpaceII";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>La Galerie VR | SpaceII</title>
      </Helmet>
      <SpaceII />
    </div>
  );
}
