import Opencalls from "../Components/LandingPage/Opencalls";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div>
      <Helmet>
        <title>La Galerie VR | Open Calls</title>
      </Helmet>
      <Opencalls />
    </div>
  );
}
