import PackForm from "../Components/LandingPage/PackForm";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div>
      <Helmet>
        <title>La Galerie VR | Contact</title>
      </Helmet>
      <PackForm />
    </div>
  );
}
