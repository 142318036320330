import React from "react";
import AGlimmerOfHope_SoloExhibition from "../Components/3D/AGlimmerOfHope_SoloExhibition";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>La Galerie VR | A Glimmer Of Hope</title>
      </Helmet>
      <AGlimmerOfHope_SoloExhibition />
    </div>
  );
}
